import React from "react";
import { graphql } from "gatsby";

import Album from "components/Album";
import { Grid, GridItem } from "components/Grid/Grid";
import Layout from "components/Layout";
import Number from "components/Number";


function Index({ data }) {
  const albums = data.allAlbumsYaml.nodes;

  return (
    <Layout>
      <Grid medium>
        {albums.map(album => (
          <GridItem key={album.number} id={album.number}>
            <Number data={album} />
            <Album data={album} />
          </GridItem>
        ))}
      </Grid>
    </Layout>
  );
}

export default Index;

export const pageQuery = graphql`
  query {
    allAlbumsYaml {
      nodes {
        artist
        cover {
          childImageSharp {
            gatsbyImageData(
              width: 800
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        cover_external
        host
        number
        played_on
        title
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`;

export const Head = (props) => <title>{props.data.site.siteMetadata.title}</title>;
